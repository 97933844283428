import { DataTable } from "primereact/datatable";
import styled from "styled-components";

export const AddItem = styled.div`
    color: var(--green-500);
    font-size: 1.14rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 1rem;
    p{
        cursor: pointer;
        transition: 300ms;
        &:hover{
            opacity: 0.7;
        }
    }
`;

export const RemoveItem = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #EF4444;
    font-weight: 700;
    font-size: 1.14rem;
    color: #EF4444;
    border-radius: 6px;
    padding: 0.75rem 1rem;
    gap: 0.5rem;
    cursor: pointer;
    transition: 300ms;
    &:hover{
        background-color: #EF4444;
        color: #fff;
    }
`;

export const TablePoints = styled(DataTable)`
    .p-datatable-wrapper{
        border: 1px solid #E5E7EB;
        border-radius: 0.5rem;
        max-height: 340px;
    }
`;