import {jwtDecode} from "jwt-decode";
export const TOKEN_KEY = process.env['REACT_APP_TOKEN_KEY'];
export const REFRESH_TOKEN_KEY = process.env['REACT_APP_REFRESH_TOKEN_KEY'];

export const Autenticado = () => ((sessionStorage.getItem(TOKEN_KEY) !== null) && (jwtDecode(sessionStorage.getItem(TOKEN_KEY)).exp > parseInt(new Date().getTime() / 1000)));
export const Token = () => sessionStorage.getItem(TOKEN_KEY);
export const RefreshToken = () => sessionStorage.getItem(REFRESH_TOKEN_KEY);
export const RegistrarToken = (token, refreshtoken) => {
	sessionStorage.setItem(TOKEN_KEY, token);
	sessionStorage.setItem(REFRESH_TOKEN_KEY, refreshtoken);
};
export const LiberarToken = () => {
	sessionStorage.removeItem(TOKEN_KEY);
	sessionStorage.removeItem(REFRESH_TOKEN_KEY);
	sessionStorage.clear();
};
export const UsuarioMaster = () => jwtDecode(sessionStorage.getItem(TOKEN_KEY)).role === process.env['REACT_APP_IDMASTER'];

export const SetarContexto = (idcampanha) => {
	sessionStorage.setItem(process.env['REACT_APP_CRYPT_KEY'], idcampanha);
}

export const ResetarContexto = () => {
	sessionStorage.removeItem(process.env['REACT_APP_CRYPT_KEY']);
}

export const Contexto = () => sessionStorage.getItem(process.env['REACT_APP_CRYPT_KEY']);

export const IdUsuarioLogado = () => jwtDecode(sessionStorage.getItem(TOKEN_KEY)).jti;

export const ptiDefaultToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiIsImN0eSI6ImFwcGxpY2F0aW9uXC9qc29uIn0.eyJhdWQiOlsicHRpLWFwaSJdLCJleHAiOjI2MjQzODUyMDEsIm5iZiI6MTY3NzYxMzk0MSwiaWF0IjoxNjc3NjE0MDAxLCJpc3MiOiJhcGlzaG93cm9vbS5wdGkub3JnLmJyXC8iLCJzdWIiOiJwdGlzaG93cm9vbUBwdGkub3JnLmJyIiwianRpIjoiMiIsInVpZCI6ImFwaSIsIm5vbWUiOiJVc3VcdTAwZTFyaW8gdmlhIEF6dXJlIiwiaWRwZXJmaWwiOjIsImFyZWEiOiJwYWluZWwiLCJpZGNsaWVudGUiOjJ9.P5v0UN_7yGQPYu6AqE--6LaiOaCOaKVJafsWnRvLAjo70PpmItH-Pc7VJrpeC-BKqFqAGn---45S4dLfKuSxoA';
export const ptiDefaultRefreshToken = 'zDbqeL8kFSugAy1axWV0t+4ceMaLidWjcVNwqqp/yLTluGxaKnv6zjDNlL8VtuOygGQvCy+5Zg6596FRqXDh+08qELSVkOXtPeN0Md01q/zkiJPKhfh2QtYwVao7FPsgQHlsivIo16WwpfMz8HIHTQdl00rwq70mqpgTkJVXgYqi';
