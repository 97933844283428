import React, {useEffect, useRef, useState} from "react";
import {useMsal} from "@azure/msal-react";
import {useNavigate} from "react-router-dom";
import {InputText} from "primereact/inputtext";
import {Toast} from "primereact/toast";
import {Button} from "primereact/button";

import Logo from "../../assets/LogoLogin.png";
import LogoMS from "../../assets/microsoft_logo.svg";
import api from "../../services/api";
import {RefreshToken, RegistrarToken, Token} from "../../services/auth";
import {Container, Tela} from "../../styles/styles";
import debug from "../../utils/debug";
import * as S from './styles';


const Login = () => {
    const {instance, inProgress, accounts, logger} = useMsal();
    const aviso = useRef(null);
    const navegacao = useNavigate();
    let dados = {email: '', senha: '', area: 'painel'};
    const legendaTitulo = 'BTXPP';
    const [credencial, setCredencial] = useState(dados);

    useEffect(() => {
        autoLogin();
    }, []);


    const autoLogin = () => {
        setCredencial(dados);
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            debug('limpando o cache...');
            // limparCache();
        }

        /*
        if (Token())  && RefreshToken() && RefreshToken() !== undefined && RefreshToken() !== 'undefined') {
            navegacao('/sistema');
        }
        */
    };

    const autenticarAD = () => {
        navegacao('/autenticacao');
    };

    const limparCache = () => {
        if ('caches' in window) {
            caches.keys().then((names) => {
                if (names.length > 0) {
                    names.forEach(name => {
                        caches.delete(name);
                    });
                    window.location.reload(true);
                }
            });

        }
    };

    const aoDigitar = (e, propriedade) => {
        const texto = (e.target && e.target.value) || '';
        let _credencial = {...credencial};
        _credencial[`${propriedade}`] = texto;
        setCredencial(_credencial);
    };

    const aoAutenticar = async (e) => {
        e.preventDefault();
        try {
            if (!credencial.email || !credencial.senha) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe o E-mail e a Senha para autenticar!'};
            } else {
                await new api().requisitar('post', 'seguranca/autenticacao', credencial).then((resposta) => {
                    if (resposta && resposta.Token) {
                        RegistrarToken(resposta.Token, resposta.RefreshToken);
                        navegacao('/sistema');
                    } else {
                        throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Ocorreu um erro na comunicação com a API'};
                    }
                });
            }
        } catch (erro) {
            if (!erro.mensagem) {
                if (erro.response && erro.response.status === 403 && erro.response.data.error_description) {
                    erro.mensagem = erro.response.data.error_description;
                    erro.titulo = 'Atenção!!';
                    erro.tipo = 'warn';
                } else {
                    erro.mensagem = erro.response.data.error_description ?? (erro.response?.data ?? 'Ocorreu um erro não tratado');
                }
            }

            aviso.current.show({
                severity: erro.tipo ?? 'error',
                summary: erro.titulo ?? 'Erro',
                detail: erro.mensagem ?? 'Ocorreu um erro não tratado',
                life: 3000
            });
        }
    };

    const aoEnter = (e) => {
        if (e.key === 'Enter' && credencial.email && credencial.senha) {
            aoAutenticar(e);
        }
    };

    return (<Container>
        <Toast ref={aviso}></Toast>
        <Tela>
            <div className="grid">
                <div className="field col-12 sm:12">
                    <div style={{padding: '0 10% 0 10%'}}>
                        <a href="https://www.instagram.com/malukaodamoda/?hl=en" target="__blank">
                            <S.Logo src={Logo} alt={legendaTitulo} title={legendaTitulo} rel={"noreferrer"}/>
                        </a>
                    </div>
                </div>
                <div className="field col-12 sm:12">
                    <S.Formulario className="float-label input-icon-left">
                        <InputText placeholder={"Informe aqui seu e-mail"} className={'campo'} autoComplete={'off'}
                                   id="login" maxLength={150} value={credencial.email}
                                   onChange={(e) => aoDigitar(e, 'email')} onKeyDown={aoEnter} required autoFocus/>
                    </S.Formulario>
                </div>
                <div className="field col-12 sm:12">
                    <S.Formulario className="float-label input-icon-left">
                        <InputText placeholder={"Informe aqui sua senha"} className={'campo'} autoComplete={'off'}
                                   type="password" id="senha" maxLength={50} value={credencial.senha}
                                   onChange={(e) => aoDigitar(e, 'senha')} onKeyDown={aoEnter} required/>
                    </S.Formulario>
                </div>
                <S.gridBotao className="col-12 lg:col-6">
                    <S.ButtonAcessar>
                        <Button label='Acessar' title='Acessar sua conta' onClick={aoAutenticar} className={'botao'}/>
                    </S.ButtonAcessar>
                </S.gridBotao>
                <S.gridBotao className="col-12 lg:col-6">
                    <S.ButtonLimpar>
                        <Button label='Limpar' title='Limpar conteúdo dos campos' onClick={() => {
                            setCredencial(dados)
                        }} className={'botao'}/>
                    </S.ButtonLimpar>
                </S.gridBotao>
                <div className="field col-12 sm:12" style={{textAlign: 'center'}}>
                    <a href="./esqueci-senha" style={{color: '#c13c1a', textDecoration: 'none'}}>Esqueci a minha
                        senha</a>
                </div>
                {/*<div className="field col-12 sm:12" style={{textAlign: 'center'}} >*/}
                {/*    <Button className="button-link" onClick={autenticarAD} style={{width: 200, backgroundColor: 'white', color:'black'}} text raised>*/}
                {/*        <img src={LogoMS} className="mr-2" style={{width:30}}/>*/}
                {/*        Sign In with Microsoft*/}
                {/*    </Button>*/}
                {/*</div>*/}
            </div>
        </Tela>
    </Container>);
};

export default Login;
