import axios from 'axios';
import {jwtDecode} from 'jwt-decode'; 
import debug from "../utils/debug";
import { Contexto, LiberarToken, RefreshToken, RegistrarToken, Token } from "./auth";

export const URLAPI = process.env.REACT_APP_URLAPI;

export default class api {
    async requisitar(metodo, endpoint, dados) {
        const idcampanha = Contexto() ?? 0;

        //const navegacao = useNavigate();
        //Notiflix.Loading.Standard();
        const resposta = new Promise((ok, falha) => {
            const token = Token();

            if (token) {
                const jwtToken = jwtDecode(token);
                console.log(jwtToken);
                if (jwtToken.exp < parseInt(new Date().getTime() / 1000)) {
                    LiberarToken();
                    window.location.href = '/';
                } else {
                    axios.request({
                        method: metodo,
                        baseURL: URLAPI,
                        url: endpoint,
                        data: dados ?? {},
                        headers: { 'Authorization': `Bearer ${token}`, 'Contexto': Contexto() ?? 0 }
                    }).then((response) => {
                        if (response.status === 200 && !response.data.error && response.data.error != 'undefined') {
                            ok(response.data);
                        } else if (response.status === 204) {
                            ok(response.data);
                        } else if (response.status === 401) {
                            LiberarToken();
                            window.location.href = '/';
                            throw { tipo: 'warn', titulo: 'Atenção!!', mensagem: response.data.error_description };
                        } else {
                            throw { tipo: 'warn', titulo: 'Atenção!!', mensagem: response.data.error_description };
                            falha(response);
                        }
                    }).catch((erro) => {
                        debug(erro);
                        const msgErro = (erro.response.data.error_description ?? erro.response.data) ?? 'Ocorreu um erro não tratado';
                        if (erro.response.status == 401) {
                            LiberarToken();
                            window.location.href = '/';
                            throw { tipo: 'warn', titulo: 'Atenção!!', mensagem: msgErro };
                        }
                        falha(erro);
                    }).finally(() => {
                        //Notiflix.Loading.Remove();
                    });
                }
            } else if (endpoint === 'seguranca/autenticacao') {
                axios.request({
                    method: metodo,
                    baseURL: URLAPI,
                    url: endpoint,
                    data: dados ?? {},
                }).then((response) => {
                    if (response.status === 200) {
                        if (response.data.error) {
                            throw { tipo: 'warn', titulo: 'Atenção!!', mensagem: response.data.error_description };
                        } else {
                            ok(response.data);
                        }
                    } else {
                        falha('Erro de autenticação');
                    }
                }).catch((erro) => {
                    debug(erro);
                    falha(erro);
                }).finally(() => {
                    //Notiflix.Loading.Remove();
                });
            }
        });

        return resposta;
    }
}
